import React from 'react'
import "./style.css"
import BA from "./BA/index"

import ba1before from "../../images/be-af-photos/before/before1.jpg" 
import ba1after from "../../images/be-af-photos/after/after1.jpg" 

const BeforeAfterSection = () => {


  function importAll(r) {
    let images = {};
    r.keys().forEach(item => images[item.replace('./', '')] = r(item));
    return images
  }

  const before = importAll(require.context("../../images/be-af-photos/before", false, /\.(png|jpe?g|svg)$/));
  const after = importAll(require.context("../../images/be-af-photos/after", false, /\.(png|jpe?g|svg)$/));

  const images = []
  Object.keys(before).forEach((el, i) => {
    images.push({before: before[`before${i+1}.jpg`], after: after[`after${i+1}.jpg`]})
  })

  images.forEach(item => {
    console.log(item)
  })


  return (
    <section  className='intro-section' id='ba'>
      <h1>Примеры наших работ</h1>
      <span>Нажимайте на картинки, чтобы увидеть преображение питомцев!</span>
      <div className='before-after-images'>
        {images.map((item, i) =>
          <BA before={item.before} after={item.after} id={`ba${i+1}`}></BA>
        )}
      </div>
    </section>
  )
}

export default BeforeAfterSection