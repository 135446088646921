import React, {useEffect, useState} from 'react'
import NavBar from './NavBar/index'
import "./style.css"
import dog from "../../images/grooming-dog.png"
import call from "../../images/phone-call.svg"
import Popup from "../PopUp/index";
import useScript from '../../hooks/useScript';


const Header = props => {
    useScript('https://w726587.yclients.com/widgetJS');

    const [phonePopup, setPhonePopup]  = useState(false);
    // const [timedPopup, setTimedPopup]  = useState(false);
    //
    // useEffect(() => {
    //     setTimeout(() => {
    //         setTimedPopup(true);
    //     }, 3000);
    // })

    return (
    <header id='main'>
      <NavBar/>
      <div className="hero">
            <div className="info">
                <h2>Салон красоты для животных в Краснодаре</h2><br></br>
                <h1>GROOMING TIME</h1><br></br>
                <h3>Сделаем вашего питомца стильным!</h3>
                <button className={"ms-button"}>Записаться</button>
            </div>
            <div className="hero-circle">
                <img src={dog} alt="dog"></img>
            </div>
            <div className="hero-circle"></div>
      </div>
      {/*-----popup-call-bubble-----*/}
        <button className="callBtn" onClick={() => setPhonePopup(true)}>
            <img src={call}></img>
        </button>
        <Popup trigger={phonePopup} setTrigger={setPhonePopup} buttonType="close" className="call-popup">
            <div className='popup-content'>
              <p className={'phoneTitle'}>Запись по номеру:</p>
              <p className={'phoneNumber'}>+7 (918) 264-02-75</p>
            </div>
        </Popup>


      {/*-----mobile--call-bubble-----*/}
        <div className="mobCallBtn">
          <a href="tel:+79182640275" >
              <img src={call}></img>
          </a>
        </div>

    </header>
  )
}

export default Header