import React, { useState } from 'react'
import "./style.css"
import clock from "../../../images/be-af-photos/clock/clock.svg"

const BA = ({before, after, id}) => {
    const [picture, setPicture] = useState(0)

    function delay(ms) {
        return new Promise((res) => {
            setTimeout(res, ms)
        })
    }

    async function playAnim(el) {
        let mouseIn = true
        const picToggler = document.querySelector(`#${id}`)
        const currentDesc = document.querySelector(`#${id}-${picture === 0 ? "before" : "after"}`)
        const nextDesc = document.querySelector(`#${id}-${picture === 1 ? "before" : "after"}`)

        currentDesc.classList.toggle("curr-desc")
        picToggler.onmouseenter = () => mouseIn = true
        picToggler.onmouseleave = () => mouseIn = false
        el.classList.add("hide-clock")
        picToggler.classList.add("pic-toggler-flash")

        await delay(300)

        el.style.display = "none"
        el.classList.remove("hide-clock")
        setPicture(picture === 0 ? 1 : 0)

        await delay(300)

        el.style.display = "block"
        el.classList.add("show-clock")
        picToggler.classList.remove("pic-toggler-flash")
        nextDesc.classList.toggle("curr-desc")


        await delay(500)

        el.classList.remove("show-clock")
        if (!mouseIn) {
            el.classList.add("hide-clock-quickly")
            await delay(180)
            el.classList.remove("hide-clock-quickly")
        }
    }

    return (
        <div className='ba'>
            <div className='pic-desc curr-desc' id={`${id}-before`}>До:</div>
            <div className='pic-desc' id={`${id}-after`}>После:</div>
            <div className='pic'>
                <img src={before} style={{zIndex: picture === 0 ? 2 : 1}}></img>
                <img src={after} style={{zIndex: picture === 1 ? 2 : 1}}></img>
                <div className='pic-toggler' id={id}>
                    <img src={clock} onClick={e => playAnim(e.target)}></img>
                </div>
            </div>
        </div>
    )
}

export default BA