import React from 'react'
import "./style.css"
import { scrollToEl } from '../../../functions.js'
import logo from "../../../images/logo3.png"
import vk_logo from "../../../images/web-logos/vk.svg"
import cross from "../../../images/cross.png"
import tg_logo from "../../../images/web-logos/telegram.svg"
import inst_logo from "../../../images/web-logos/instagram.png"
import useWindowWidth from '../../../hooks/useWindowWidth'


const NavBar = () => {
  let darkTheme = false
  const {width} = useWindowWidth()

  function changeTheme() {
    if (darkTheme) {
      document.documentElement.style.setProperty('--bg-color', '#27312c');
      document.documentElement.style.setProperty('--main-color', '#54955e');
      document.documentElement.style.setProperty('--bg-color-dark', '#232a27');
      document.documentElement.style.setProperty('--main-color-dark', '#3d6e42');
    } else {
      document.documentElement.style.setProperty('--main-color', '#27312c');
      document.documentElement.style.setProperty('--bg-color', '#54955e');
      document.documentElement.style.setProperty('--main-color-dark', '#232a27');
      document.documentElement.style.setProperty('--bg-color-dark', '#3d6e42');
    }
    darkTheme = !darkTheme
  }

  function toggleOptions(choice) {
    const phoneNav = document.querySelector(".phone-nav-options")
    if (choice) {
      phoneNav.style.display = "flex"
      phoneNav.classList.add("phone-nav-appear")
    } else {
      phoneNav.style.display = "none"
      phoneNav.classList.remove("phone-nav-appear")
    }
  }

  function phoneScroll(id) {
    scrollToEl(id)
    toggleOptions(false)
  }

  window.onresize = () => {

  }

  window.onscroll = () => {
    if (window.scrollY > 0) {
      document.querySelector(".fixed-nav").style.top = "0px"
    } else {
      document.querySelector(".fixed-nav").style.top = "-150px"
    }
  }

  return (
    <>
    <div className='navbar'>
      <div>
        <div className="nav-logo" onClick={changeTheme}>
          <img src={logo} alt="logo"></img>
        </div>
        <div className='nav-content'>
          {width > 1080 ? 
            <>
              <div className='nav-options'>
                <div className="nav-option" onClick={() => scrollToEl("main")}>Главная<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("goods")}>Услуги<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("gall")}>Галерея<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("ba")}>Примеры работ<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("map")}>Контакты<div className='underline'></div></div>
              </div>
              <div className='nav-links'>
                <a href='https://vk.com/groomingtime23' target="_blank"><img src={vk_logo}></img></a>
                <a href='https://t.me/GroomingTime' target="_blank"><img src={tg_logo}></img></a>
                {/*<a href='https://www.instagram.com/grooming_time_krd' target="_blank"><img src={inst_logo}></img></a>*/}
              </div>
            </>
            :
            <>
              <div className='burger' onClick={() => toggleOptions(true)}>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>

          }

          
        </div>
      </div>
    </div>
    <div className='fixed-nav'>
      <div>
        <div className="fix-nav-logo" onClick={changeTheme}>
          <img src={logo} alt="logo"></img>
        </div>
        <div className='nav-content'>
          {width > 1080 ?
            <>
              <div className='nav-options'>
                <div className="nav-option" onClick={() => scrollToEl("main")}>Главная<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("goods")}>Услуги<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("gall")}>Галерея<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("ba")}>Примеры работ<div className='underline'></div></div>
                <div className="nav-option" onClick={() => scrollToEl("map")}>Контакты<div className='underline'></div></div>
              </div>
              <div className='nav-links'>
                <a href='https://vk.com/groomingtime23' target="_blank"><img src={vk_logo}></img></a>
                <a href='https://t.me/GroomingTime' target="_blank"><img src={tg_logo}></img></a>
                {/*<a href='https://www.instagram.com/grooming_time_krd' target="_blank"><img src={inst_logo}></img></a>*/}
              </div>
            </>
            :
            <>
              <div className='burger' onClick={() => toggleOptions(true)}>
                <div></div>
                <div></div>
                <div></div>
              </div>


            </>
          }
        </div>
      </div>
    </div>
    <div className='phone-nav-options'>
      <img className="close-popup" src={cross} onClick={() => toggleOptions(false)}></img>

      <div className="nav-option" onClick={() => phoneScroll("main")}>Главная<div className='underline'></div></div>
      <div className='line'></div>
      <div className="nav-option" onClick={() => phoneScroll("goods")}>Услуги<div className='underline'></div></div>
      <div className='line'></div>
      <div className="nav-option" onClick={() => phoneScroll("gall")}>Галерея<div className='underline'></div></div>
      <div className='line'></div>
      <div className="nav-option" onClick={() => phoneScroll("ba")}>Примеры работ<div className='underline'></div></div>
      <div className='line'></div>
      <div className="nav-option" onClick={() => phoneScroll("map")}>Контакты<div className='underline'></div></div>
    </div>
    </>
  )
}

export default NavBar