import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import "./style.css"

const GallerySection = () => {
    const photoURLs = useRef([]);
    const [photos, setPhotos] = useState([])
    const [singlePhotoMod, setSinglePhotoMod] = useState(false)
    const [singlePhotoIndex, setSinglePhotoIndex] = useState(0)

    function openSinglePhotoMod (n) {
        setSinglePhotoMod(true)
        setSinglePhotoIndex(n)
    }

    function loadPhotos() {
        const diff = photoURLs.current.length - photos.length
        const photosLoaded = diff > 4 ? 4 : diff

        for (let i = photos.length; i < photos.length+photosLoaded; i++) {
            setPhotos(prev => [...prev, photoURLs.current[i]])
        }
    }

    function changeSinglePhoto(direction) {
        switch (direction) {
            case "left": {
                setSinglePhotoIndex(singlePhotoIndex - 1)
                break
            }
            case "right": {
                setSinglePhotoIndex(singlePhotoIndex + 1)
                break
            }
        }
    }

    useEffect(() => {
        axios.get("https://groomingtime-service.herokuapp.com/photos")
        .then(res => {
            const posts = res.data

            posts.forEach(post => {
                const photo = post?.sizes[3]?.url
                if (photo) {
                    photoURLs.current.push(photo)
                }
            })
            loadPhotos()
        })
      }, [])

  return (
    <section className='gallery'>
        <h1 id="gall">Галерея</h1>
        <h3>Еще больше фотографий питомцев!</h3>
        <div className='photos-container'>
            {photos.map(photo => 
                <div>
                    <img src={photo} onClick={() => openSinglePhotoMod(photos.indexOf(photo))}></img>
                </div>
                )
            }
        </div>
        {singlePhotoMod
        ? 
        <>
            <div className='single-photo-menu'>
                <div
                    className='arrow'
                    onClick={() => singlePhotoIndex > 0 ? changeSinglePhoto("left") : null}>
                    {"<"}
                </div>
                <div className='single-photo'>
                    <img src={photoURLs.current[singlePhotoIndex]}></img>
                </div>
                <div
                    className='arrow'
                    onClick={() => singlePhotoIndex < photoURLs.current.length - 1 ? changeSinglePhoto("right") : null}>
                    {">"}
                </div>
                <div className='single-photo-mod-hider' onClick={() => setSinglePhotoMod(false)}/>
            </div>
            
        </>

        :
        <></>
        }
        {photoURLs.current.length > photos.length
         ? <button onClick={loadPhotos}>{"Показать еще"}</button>
         : <></>
        }
    </section>
  )
}

export default GallerySection