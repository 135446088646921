import React, { useEffect, useState } from 'react'
import "./style.css"
import "../../fonts/fontConnect.css"
import logo from "../../images/logo3.png"
import useScript from '../../hooks/useScript'
import Popup from '../PopUp'
import axios from "axios"

const GoodsSection = () => {
  useScript('https://w726587.yclients.com/widgetJS');

  const [goodPopup, setGoodPopup] = useState(false)
  const [goodIndex, setGoodIndex] = useState(0)
  const [goods, setGoods] = useState([])

  useEffect(() => {
    axios.get("https://groomingtime-service.herokuapp.com/goods").then(res => setGoods(res.data))
  }, [])

  return (
    <section className='goods-section'>
      {
        goods.length > 1
        ?
        <>
          <h1 id='goods'>Наши услуги</h1>
          <div className='goods-block'>
            {goods.map((good, index) =>
                  <div className='good-item' onClick={() => {setGoodPopup(true); setGoodIndex(index)}}>
                    <img className='good-bg-logo' src={logo}></img>
                    <img src={good.thumb_photo} alt="good-img"></img>
                    <div className='info'>
                      <span>{good.title}</span>
                      <button onClick={() => {setGoodPopup(true); setGoodIndex(index)}}>Записаться</button>
                    </div>
                  </div>
            )}
          </div>

          <Popup trigger={goodPopup} setTrigger={setGoodPopup} className="good-popup" buttonType="ms-booking">
            <div className='popup-content'>
              <div className='upper'>
                <img src={goods[goodIndex].thumb_photo} alt="good-img" className='popup-img'></img>
                <div>
                  <h2>{goods[goodIndex].title}</h2>
                </div>
              </div>
              <h4>{goods[goodIndex].description}</h4>
            </div>
          </Popup> 
        </>
        :
        <></>
      }
         
    </section>
  )
}

export default GoodsSection