import React from 'react'
import logo from "../../images/logo1.png"
import vk_logo from "../../images/web-logos/vk.svg"
import tg_logo from "../../images/web-logos/telegram.svg"
import inst_logo from "../../images/web-logos/instagram.png"
import dmst from "../../images/logodtc-01.png"
import "./style.css"
import { scrollToEl } from "../../functions.js"

const Footer = () => {
  return (
    <footer>
        <img src={logo}/>
        <div className='line'></div>
        <div className='options'>
          <div className='navigation'>
            <div className='option' onClick={() => scrollToEl("main")}>Наверх<div className='underline'></div></div>
            <div className='option' onClick={() => scrollToEl("goods")}>Услуги<div className='underline'></div></div>
            <div className='option' onClick={() => scrollToEl("gall")}>Галерея<div className='underline'></div></div>
            <div className='option' onClick={() => scrollToEl("ba")}>Примеры работ<div className='underline'></div></div>
            <div className='option' onClick={() => scrollToEl("map")}>Контакты<div className='underline'></div></div>
          </div>
          <div className='contacts'>
            <div>
                <div className='option'><a className={'mapLink'} href='tel:+79182640275' >+7 (918) 264-02-75</a></div>
                <div className='option mail'><a className={'mapLink mail'} href='mailto:abuse@groomingtime.ru' >abuse@groomingtime.ru</a></div>
                <div className='option mail'><a className={'mapLink mail'} href='mailto:techunit@groomingtime.ru' >techunit@groomingtime.ru</a></div>
                <div className='option'><a className={'mapLink'} href='https://yandex.kz/profile/145851805513?no-distribution=1&source=wizbiz_new_map_single' target='_blank'>улица Фадеева, 429/1,<br></br>Краснодар</a></div>
            </div>
            <div className='social-media'>
              <a href='https://vk.com/groomingtime23' target="_blank"><img src={vk_logo}></img></a>
              <a href='https://t.me/GroomingTime' target="_blank"><img src={tg_logo}></img></a>
              {/*<a href='https://www.instagram.com/grooming_time_krd' target="_blank"><img src={inst_logo}></img></a>*/}
              <img src={dmst} className={"socdmst"}></img>
            </div> 
          </div>
        </div>
        <div className={"dmst-logo"}>
            <img src={dmst}></img>
        </div>
        <h4>Рады видеть вас в Груминг Тайм!</h4>
        <h5 className={"copyright"}>
            Website by
            <br></br>
            © 2022 ООО "DemasiaTech"
            <br></br>
            © 2022 "DemasiaTech" LLP
        </h5>
    </footer>
  )
}

export default Footer