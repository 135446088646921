import Header from './Components/Header/index';
import GoodsSection from './Components/GoodsSection/index';
import BeforeAfterSection from './Components/BeforeAfterSection/index';
import MapSection from './Components/MapSection/index';
import Footer from './Components/Footer/index'
import GallerySection from './Components/GallerySection';

function App() {
  return (
    <>
    <Header/>
    <GoodsSection/>
    <GallerySection/>
    <BeforeAfterSection/>
    <MapSection/>
    <Footer/>
    </>
  );
}



export default App;
