import React from 'react';
import cross from "../../images/cross.png";
import useScript from '../../hooks/useScript';
import "./style.css"

function Popup({trigger, setTrigger, className, children, buttonType}) {
    useScript('https://w726587.yclients.com/widgetJS');


    return (
        <div className="popup-bg" style={{display: trigger ? "flex" : "none"}}>
            <div className={`popup ${className}`}>
                <img className="close-popup" src={cross} onClick={() => setTrigger(false)}></img>
                {children}
                {buttonType === "close" ? 
                    <button onClick={() => setTrigger(false)}>Закрыть</button> :
                    <button className="ms-button">Записаться онлайн</button>
                }
            </div>
            <div className="popup-hide" onClick={() => setTrigger(false)}></div>
        </div>
    );
}

export default Popup;