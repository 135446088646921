import React from 'react'
import "./style.css"
import logo from "../../images/logo3.png"

const MapSection = () => {
  return (
    <section className='map-section' id='map'>
      <img src={logo}/>
      <h1>Контакты и Отзывы</h1>
      <div className='contact-info'>
      </div>
      <div className='container'>
        <div className='yandexMap'>
          <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad05283cef078b07cc0f10b56887a307024e0e65b90179127b1ed5d756c65d79d&amp;source=constructor" width="100%" height="100%" frameBorder="0" className='yandexIframe'></iframe>
        </div>
        <div className='yandexTestimonials' >
            <iframe src="https://yandex.ru/maps-reviews-widget/145851805513?comments" className={'yandexIframe'}></iframe>
            <a href="https://yandex.kz/maps/org/groomingtime/145851805513/" target="_blank" className={'yandexATag'}>GroomingTime на карте Краснодара — Яндекс Карты</a>
        </div>
      </div>
    </section>
  )
}

export default MapSection